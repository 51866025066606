import Home from './containers/home/home';
import logo from './logo.svg';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BrowserRouter, Route, Router, Routes } from 'react-router-dom';
import Services from './containers/services/services';
import Footer from './common/footer';
import ContactUs from './containers/contactUs/contactUs';
import './assets/css/styles.css';
import './assets/css/footer-style.css';
import ServiceSingle from './containers/serviceSingle/serviceSingle';
import AboutUs from './containers/about-us/aboutUs';
import AnimatedCursor from "react-animated-cursor"
import Portfolio from './containers/portfolio/portfolio';

function App() {

  // document.body.addEventListener("mousemove", (e) => {
  //   console.log(e.clientX)
  //   document.getElementById("cursor").style.top = `${e.clientY - 30}px`
  //   document.getElementById("cursor").style.left = `${e.clientX - 30}px`
  //   // document.getElementById("cursor").style.transform =  `translate(${e.offsetX}px, ${e.offsetY}px)`
  // })

  return (
    <div className="App">
      {/* <AnimatedCursor
        innerSize={8}
        outerSize={35}
        innerScale={1}
        outerScale={2}
        outerAlpha={0}
        hasBlendMode={true}
        innerStyle={{
          backgroundColor: 'white'
        }}
        outerStyle={{
          border: '3px solid white'
        }}
      /> */}
      {/* <img id='cursor' src='assets/images/cursor-running.png' style={{ position: "fixed", zIndex: 1, transition: "0.3s" }} /> */}
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/services" element={<Services />} />
          <Route exact path="/services/:id" element={<ServiceSingle />} />
          <Route exact path="/contact-us" element={<ContactUs />} />
          <Route exact path="/about-us" element={<AboutUs />} />
          <Route exact path="/portfolio" element={<Portfolio />} />
          <Route exact path="/portfolio/:id" element={<Portfolio />} />
        </Routes>
        <Footer />
        <div className='whatsappIcon'>
          {/* <a href='https://wa.me/971504773791' target='_blank'></a> */}
          <a href='https://wa.me/923307083831' target='_blank'>
            <img src={require('./assets/images/whatsapp.png')} />
          </a>
        </div>
      </BrowserRouter>
      {/* <Home /> */}
    </div>
  );
}

export default App;
