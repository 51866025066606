import Slider from "react-slick";
import Header from "../../common/header";
import ContactUs from "../contactUs/contactUs";
import { NavLink } from "react-router-dom";
import ReactTyped from "react-typed";
import { useEffect } from "react";

export default function Home() {
  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: false,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    var target = document.getElementById("target");
    var targetBox = document.getElementById("target-box");

    if (targetBox) {
      targetBox.addEventListener("mousemove", mouseClicked);

      targetBox.addEventListener("mouseover", () => {
        target.style.display = "block";
      });

      targetBox.addEventListener("mouseout", () => {
        target.style.display = "none";
      });

      function mouseClicked(event) {
        console.log(event);

        var xposition =
          event.clientX - target.offsetLeft - target.offsetWidth / 2;
        var yposition =
          event.clientY - target.offsetTop - target.offsetHeight / 2;
        target.style.transform =
          "translate(" + (xposition - 30) + "px," + 0 + "px)";
        console.log(event);
      }
    }
  }, []);

  return (
    <div className="home">
      <div class="b-img">
        <Header />
        <div class="section1">
          <div className="container">
            <ReactTyped
              loop
              typeSpeed={50}
              backSpeed={20}
              strings={[
                "Dynamic Marketing Agency excelling in creativity and success.",
              ]}
              smartBackspace
              shuffle={false}
              backDelay={1000}
              fadeOut={false}
              fadeOutDelay={100}
              loopCount={0}
              showCursor={false}
            />
          </div>
        </div>
      </div>

      <section class="section2">
        <video id="background-video-section2" autoPlay muted loop>
          <source
            src="assets/images/pexels-luciann-photography-3971351-3840x2160-25fps.mp4"
            type="video/mp4"
          />
        </video>

        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="head text-white text-center">
                <h1>Digital Marketing Process</h1>
              </div>
            </div>

            <div className="col-md-12 row" id="target-box">
              <div
                className="hoverDiv col-lg-3 col-md-12 col-sm-12"
                id="target"
              ></div>
              <div class="col-lg-3 col-md-12 col-sm-12">
                <div class="process-div">
                  <div class="heading text-white">
                    <h2>Workshops</h2>
                  </div>
                  <div class="text-div text-white text-justify">
                    <p>
                      <b>-</b> &nbsp; Business analysis
                      <br />
                      <b>-</b> &nbsp; Campaign Objective
                      <br />
                      <b>-</b> &nbsp; Preparing proposal
                      <br />
                      <b>-</b> &nbsp; Deciding deliverables
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-12 col-sm-12">
                <div class="process-div">
                  <div class="heading text-white">
                    <h2>Planning</h2>
                  </div>
                  <div class="text-div text-white text-justify">
                    <p>
                      <b>-</b> &nbsp; Industry research
                      <br />
                      <b>-</b> &nbsp; Competitor research
                      <br />
                      <b>-</b> &nbsp; Selecting marketing channels
                      <br />
                      <b>-</b> &nbsp; Strategy development
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-12 col-sm-12">
                <div class="process-div">
                  <div class="heading text-white">
                    <h2>A/B Testing</h2>
                  </div>
                  <div class="text-div text-white text-justify">
                    <p>
                      <b>-</b> &nbsp; Experimenting with ads
                      <br />
                      <b>-</b> &nbsp; Experimenting with content
                      <br />
                      <b>-</b> &nbsp; Testing SEO techniques
                      <br />
                      <b>-</b> &nbsp; Analyzing audience behavior
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-12 col-sm-12">
                <div class="process-div">
                  <div class="heading text-white">
                    <h2>Executive</h2>
                  </div>
                  <div class="text-div text-white text-justify">
                    <p>
                      <b>-</b> &nbsp; Creating campaign
                      <br />
                      <b>-</b> &nbsp; Content product
                      <br />
                      <b>-</b> &nbsp; Strategy optimization
                      <br />
                      <b>-</b> &nbsp; Anaylsis
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="section3">
        <section class="hmsec2">
          <div class="container-fluid">
            <div class="hmsecpad">
              <div class="row align-items-center">
                <div
                  class="col-md-6 wow fadeInLeft text-white"
                  data-wow-delay="0.4s"
                >
                  <h6>WHAT WE DO</h6>
                  <h4>What is Mangopress?</h4>
                </div>
                <div class="col-md-6 wow fadeInRight" data-wow-delay="0.4s">
                  <p>
                    MangoPress offers a comprehensive advertising and digital
                    marketing services, ensuring the success of your Digital
                    Marketing campaign at any stage of your product's life
                    cycle. As a reputable Online Marketing Agency, we have
                    specialized teams that handle SEM, Creative designing, SEO,
                    PR, Email Marketing, Snapchat Marketing, Tiktok Marketing as
                    well as Corporate Design, and Consulting services.
                  </p>
                </div>
              </div>
            </div>
            <Slider autoplay={true} autoplaySpeed={2000} {...settings}>
              <div class="dowrap">
                <div class="dobox">
                  <img src="assets/images/service1.png" alt="" />
                </div>
                <NavLink
                  to="/services/Website-Design-and-Development"
                  tabindex="-1"
                >
                  Website design <br />
                  and development
                </NavLink>
              </div>
              <div class="dowrap">
                <div class="dobox">
                  <img src="assets/images/service2.png" alt="" />
                </div>
                <NavLink
                  to="/services/Search-Engine-Optimization"
                  tabindex="-1"
                >
                  Search Engine
                  <br />
                  Optimization
                </NavLink>
              </div>
              <div class="dowrap">
                <div class="dobox">
                  <img src="assets/images/service3.png" alt="" />
                </div>
                <NavLink to="/services/Google-Ads" tabindex="-1">
                  Google Ads
                </NavLink>
              </div>
              <div class="dowrap">
                <div class="dobox">
                  <img src="assets/images/service4.png" alt="" />
                </div>
                <NavLink to="/services/Content-Writing" tabindex="-1">
                  Content Writing
                </NavLink>
              </div>
              <div class="dowrap">
                <div class="dobox">
                  <img src="assets/images/service5.png" alt="" />
                </div>
                <NavLink to="/services/Creative-Designing" tabindex="0">
                  Creative Designing
                </NavLink>
              </div>
              <div class="dowrap">
                <div class="dobox">
                  <img src="assets/images/service6.png" alt="" />
                </div>
                <NavLink to="/services/Social-Media-Marketing" tabindex="0">
                  Social Media <br />
                  Marketing
                </NavLink>
              </div>
              <div class="dowrap">
                <div class="dobox">
                  <img src="assets/images/service7.png" alt="" />
                </div>
                <NavLink to="/services/Email-Marketing" tabindex="0">
                  Email <br />
                  Marketing
                </NavLink>
              </div>
              <div class="dowrap">
                <div class="dobox">
                  <img src="assets/images/service8.png" alt="" />
                </div>
                <NavLink to="/services/Tiktok-Marketing" tabindex="0">
                  Tiktok
                  <br />
                  Marketing
                </NavLink>
              </div>
              <div>
                <div class="dowrap">
                  <div class="dobox">
                    <img src="assets/images/service9.png" alt="" />
                  </div>
                  <NavLink to="/services/Snapchat-Marketing" tabindex="-1">
                    Snapchat
                    <br />
                    Marketing
                  </NavLink>
                </div>
              </div>
            </Slider>
          </div>
        </section>
      </section>

      <section class="section4">
        <div class="container">
          <video width="100%" autoPlay muted playsinline loop>
            <source
              src={require("../../assets/images/mangopressintro.mp4")}
              type="video/mp4"
            />
          </video>
        </div>
      </section>

      <section class="section5 text-white">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="head">
                <h1>
                  Find<span> best way to market your brand</span>
                </h1>
              </div>
            </div>
          </div>

          <div class="row grid-layout">
            <div class="col-lg-3 col-md-12 col-sm-12">
              <NavLink to="/portfolio/creative-designing">
                <div class="pic pic-1"></div>
              </NavLink>
            </div>
            <div class="col-lg-9 col-md-12">
              <div class="row h-100">
                <div class="col-md-4">
                  <div class="row h-100">
                    <div class="col-md-12 col-sm-6">
                      <NavLink to="/portfolio/social-media-marketing">
                        <div class="pic pic-2"></div>
                      </NavLink>
                    </div>
                    <div class="col-md-12 col-sm-6">
                      <NavLink to="/portfolio/social-media-marketing">
                        <div class="pic pic-3"></div>
                      </NavLink>
                    </div>
                  </div>
                </div>
                <div class="col-md-8">
                  <div class="row h-100">
                    <div class="col-md-12 col-sm-6">
                      <NavLink to="/portfolio/website-design-and-development">
                        <div class="pic pic-4"></div>
                      </NavLink>
                    </div>
                    <div class="col-md-12 col-sm-6">
                      <NavLink to="/portfolio/website-design-and-development">
                        <div class="pic pic-5"></div>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ContactUs name={true} />
    </div>
  );
}
