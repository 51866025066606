import { NavLink } from "react-router-dom";

export default function Header(props) {

    const openNav = () => {
        document.getElementById("sideDrawer").classList.toggle("menuOpen")
    }

    return (
        <header style={{ position: props.bool ? "relative" : "absolute" }}>
            <div class="header-top">
                <div class="container-fluid pad-zero">
                    <div class="headerwrap">
                        <div class="headnumb">
                            {/* <a href="tel:+971509550779">Call Now: +971 504773791</a> */}
                            <a href="tel:+923342085629">Call Now: +92 334 2085629</a>                       </div>
                        <ul class="headbtns">
                            <li class="first"><a href="mailto:connect@mangopress.com">Email: connect@themangopress.com</a></li>
                            <li class="last"><a href="javascript:;" class="popbtn">Schedule a Meetup!</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="main-header">
                <div class="container-fluid">
                    <div class="row align-items-center">
                        <div class="col-md-3 col-8 text-left">
                            <NavLink to="/" class="logo">
                                <img src={require("../assets/images/mango-press.png")} alt="" />
                            </NavLink>
                        </div>
                        <div class="col-md-9 col-4 text-right">
                            <ul class="menu">
                                <li class="active first"><NavLink to="/">Home</NavLink></li>
                                <li class=""><NavLink to="/about-us">About Us</NavLink></li>
                                <li class=""><NavLink to="/services">Services</NavLink></li>
                                <li class=""><NavLink to="/portfolio/">Portfolio</NavLink></li>
                                {/* <li class=""><NavLink to="/clients">Our Clients</NavLink></li> */}
                                {/* <li class=""><NavLink to="/blogs">Blogs</NavLink></li> */}
                                <li class=""><NavLink to="/contact-us">Contact Us</NavLink></li>
                            </ul>

                            <div className="sideDrawer">
                                <a style={{ marginRight: "20px" }} href="tel:+923378422007">
                                    <img className="menuIcon" src={require('../assets/images/callicon.jpg')} width={"100%"} />
                                </a>
                                <img className="menuIcon" src={require('../assets/images/menuicon.png')} width={"100%"} onClick={openNav} />

                                <ul class="menu" id="sideDrawer">
                                    <li>
                                        <div onClick={openNav} className="closeIcon">
                                            <img width={"100%"} src={require('../assets/images/closeIcon.png')} />
                                        </div>
                                    </li>
                                    <li class="active first"><NavLink to="/">Home</NavLink></li>
                                    <li class=""><NavLink to="/about-us">About Us</NavLink></li>
                                    <li class=""><NavLink to="/services">Services</NavLink></li>
                                    <li class=""><NavLink to="/portfolio">Portfolio</NavLink></li>
                                    {/* <li class=""><NavLink to="/clients">Our Clients</NavLink></li> */}
                                    {/* <li class=""><NavLink to="/blogs">Blogs</NavLink></li> */}
                                    <li class=""><NavLink to="/contact-us">Contact Us</NavLink></li>

                                    <li>
                                        <NavLink to="/" className="sideLogo">
                                            <img src={require("../assets/images/mango-press.png")} alt="" />
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>

    )
}