import Slider from "react-slick";
import Header from "../../common/header";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import ContactUs from "../contactUs/contactUs";
import ReactTyped from "react-typed";
import { NavLink } from "react-router-dom";

import ssm1 from '../../assets/images/Website Service Page/Social Media Marketing Service Page/Social Media Marekting Slide 1.jpg'
import ssm2 from '../../assets/images/Website Service Page/Social Media Marketing Service Page/Social Media Marekting Slide 2.jpg'
import ssm3 from '../../assets/images/Website Service Page/Social Media Marketing Service Page/Social Media Marekting Slide 3.jpg'
import ssm4 from '../../assets/images/Website Service Page/Social Media Marketing Service Page/Social Media Marekting Slide 4.jpg'

import wd1 from '../../assets/images/Website Service Page/Website Development Service Page/Website Slide 1.jpg'
import wd2 from '../../assets/images/Website Service Page/Website Development Service Page/Website Slide 2.jpg'
import wd3 from '../../assets/images/Website Service Page/Website Development Service Page/Website Slide 3.jpg'

import cd1 from '../../assets/images/Website Service Page/Creative Designing Service Page/Creative Designing Slide 1.jpg'
import cd2 from '../../assets/images/Website Service Page/Creative Designing Service Page/Creative Designing Slide 2.jpg'
import cd3 from '../../assets/images/Website Service Page/Creative Designing Service Page/Creative Designing Slide 3.jpg'

import ga1 from '../../assets/images/Website Service Page/Google Ads Service Page/Google Ads Slide 1.jpg'
import ga2 from '../../assets/images/Website Service Page/Google Ads Service Page/Google Ads Slide 2.jpg'

import seo1 from '../../assets/images/Website Service Page/SEO Service Page/SEO Slide 1.jpg'
import seo2 from '../../assets/images/Website Service Page/SEO Service Page/SEO Slide 2.jpg'

import cw1 from '../../assets/images/Website Service Page/Content Writing Service Page/Content Writing Slide 1 .jpg'
import cw2 from '../../assets/images/Website Service Page/Content Writing Service Page/Content Writing Slide 2.jpg'

import em1 from '../../assets/images/Website Service Page/Email Marketing Service Page/Email Marketing Slide 1.jpg'
import em2 from '../../assets/images/Website Service Page/Email Marketing Service Page/Email Marketing Slide 2.jpg'

import tm1 from '../../assets/images/Website Service Page/Tiktok Marketing Service Page/Tiktok Marketing Slide 1.jpg'
import tm2 from '../../assets/images/Website Service Page/Tiktok Marketing Service Page/Tiktok Marketing Slide 2.jpg'

import sm1 from '../../assets/images/Website Service Page/Snapchat Marketing Service Page/Snapchat Marketing Slide 1.jpg'
import sm2 from '../../assets/images/Website Service Page/Snapchat Marketing Service Page/Snapchat Marketing Slide 2.jpg'


let content = [
    {
        key: "Social-Media-Marketing",
        name: "Social Media Marketing",
        slides: [
            {
                heading: "",
                para: "Our expertise in social media marketing enables us to unlock the potential for our clients to distinguish themselves from competitors and accomplish their business goals. Through continuous learning, implementing innovative strategies, and utilizing performance metrics, we identify and capitalize on the unique opportunities available to each client.",
                img: ssm1
            },
            {
                heading: "",
                para: "Social media marketing is a strategic approach that utilizes various platforms to promote businesses, products, or services. It involves leveraging the power of social media platforms to enhance internal communication, engage with the target audience, and drive marketing objectives.",
                img: ssm2
            },
            {
                heading: "",
                para: "MangpoPress, a trusted social media company in Dubai, empowers your online presence. Our dedicated team of digital marketers and social media experts works diligently to understand your business, delivering impactful results. With our expertise, we transform your company's social media profiles into dynamic, engaging platforms that captivate and interact with your audience.",
                img: ssm3
            },
            {
                heading: "",
                para: "With our highly skilled social media agency services in Dubai, we enable you to establish meaningful connections and engage your target audience effectively. Our expertise extends to expanding your business's reach and discovering new platforms for growth. Through meticulously crafted, creative, engaging, and interactive social media campaigns, we drive tangible results. Reach out to our team of social media specialists today to explore the possibilities!",
                img: ssm4
            },
        ]
    },
    {
        key: "Website-Design-and-Development",
        name: "Website Design and Development",
        slides: [
            {
                heading: "",
                para: "MangoPress Website Development Company in Dubai has gained valuable insights and applied advancements, procedures, and evaluations enabling us to identify the potential for our clients to distinguish themselves from their competitors and attain their desired business goals.",
                img: wd1
            },
            {
                heading: "",
                para: `The term "web development" refers to the creation of web pages for the internet or intranet with the aim of enhancing internal communication or promoting products for an organization. This involves leveraging software technology on both the server and client sides, incorporating database processes and utilizing search engines to present information and facilitate various tasks. Feel free to reach out to our Dubai-based Website Development Company for assistance in this regard.`,
                img: wd2
            },
            {
                heading: "",
                para: "By harnessing the power of the internet, we propel your business to unprecedented heights. With over 1.2 billion daily users, the global online market holds immense potential. Our expertise lies in crafting and fine-tuning the information and communication channels essential for maximizing your digital presence. From web pages to social networks and blogs, we specialize in creating and optimizing these platforms to ensure your digital business thrives to its fullest potential.",
                img: wd3
            }
        ]
    },
    {
        key: "Creative-Designing",
        name: "Creative Designing",
        slides: [
            {
                heading: "",
                para: "MangoPress, a prominent Creative Designing agency, excels in bringing ideas to life through visually captivating and immersive experiences. Our team of skilled designers specializes in transforming brands with innovative graphic design, logo creation, branding, and visual identity development. With an unwavering focus on aesthetics and a drive for creativity, we deliver impactful designs that mesmerize your audience and enhance your brand's visibility.",
                img: cd1
            },
            {
                heading: "",
                para: "With our exceptional Creative Designing services, we empower you to transform your ideas into captivating visual experiences. Our skilled team of designers possesses the expertise to bring your brand to life through innovative graphic design solutions. From stunning logo creation to comprehensive branding strategies and the development of visually engaging identities, we ensure your brand stands out from the crowd.",
                img: cd2
            },
            {
                heading: "",
                para: "Our meticulous approach, combined with creativity and interactivity, results in compelling designs that captivate your audience. Connect with our team of experts today and unlock the full potential of your creative vision!",
                img: cd3
            }
        ]
    },
    {
        key: "Google-Ads",
        name: "Google Ads",
        slides: [
            {
                heading: "",
                para: "Unlock the power of Google Ads with our expert services. At our agency, we specialize in maximizing the impact of your online advertising campaigns. Our skilled team of Google Ads professionals will help you reach your target audience effectively, drive qualified traffic to your website, and generate measurable results.",
                img: ga1
            },
            {
                heading: "",
                para: "From strategic campaign planning to precise targeting and compelling ad copy, we ensure your brand stands out in the competitive digital landscape. Trust us to optimize your Google Ads campaigns and achieve the highest return on your advertising investment. Contact us today to elevate your online presence and propel your business forward.",
                img: ga2
            }
        ]
    },
    {
        key: "Search-Engine-Optimization",
        name: "Search Engine Optimization",
        slides: [
            {
                heading: "",
                para: "Elevate your online presence with our comprehensive Search Engine Optimization (SEO) services. As a leading agency, we specialize in optimizing your website to rank higher in search engine results. Our team of skilled SEO professionals employs industry-best practices to enhance your website's visibility, drive organic traffic, and boost your online credibility.",
                img: seo1
            },
            {
                heading: "",
                para: "From thorough keyword research and on-page optimization to strategic link building and content marketing, we ensure your website is fully optimized for search engines. With our data-driven approach and continuous monitoring, we help you stay ahead of the competition and achieve long-term success. Experience the power of SEO and unlock the full potential of your online business. Contact us today to discuss your SEO goals and let us take your website to new heights.",
                img: seo2
            }
        ]
    },
    {
        key: "Content-Writing",
        name: "Content Writing",
        slides: [
            {
                heading: "",
                para: "Transform your online presence with our exceptional content writing services. As a premier agency, we specialize in crafting compelling and engaging content that captivates your target audience. Our talented team of writers combines creativity with strategic thinking to deliver high-quality content tailored to your brand's unique voice and objectives. Whether you need blog posts, website copy, social media content, or email newsletters, we ensure each piece is meticulously researched, well-written, and optimized for search engines.",
                img: cw1
            },
            {
                heading: "",
                para: "With our content writing expertise, we help you establish thought leadership, drive organic traffic, and enhance your brand's credibility. Experience the power of captivating content. Contact us today to discuss your content writing needs and let us bring your brand story to life.",
                img: cw2
            }
        ]
    },
    {
        key: "Email-Marketing",
        name: "Email Marketing",
        slides: [
            {
                heading: "",
                para: "Unlock the potential of your email marketing campaigns with our comprehensive services. As a leading agency, we specialize in creating highly effective email marketing strategies that engage and convert your audience. Our expert team of email marketers combines creativity, data analysis, and strategic planning to craft compelling email campaigns tailored to your brand's objectives.",
                img: em1
            },
            {
                heading: "",
                para: "From designing eye-catching templates to crafting persuasive copy and implementing advanced segmentation techniques, we ensure your emails deliver maximum impact and drive measurable results. With our attention to detail and industry best practices, we help you nurture customer relationships, increase conversions, and maximize your ROI. Experience the power of email marketing. Contact us today to discuss your goals and let us take your campaigns to the next level.",
                img: em2
            }
        ]
    },
    {
        key: "Tiktok-Marketing",
        name: "Tiktok Marketing",
        slides: [
            {
                heading: "",
                para: "Unleash the power of TikTok marketing with our specialized services. As a leading agency, we are experts in creating impactful TikTok marketing strategies that resonate with your target audience. Our skilled team of TikTok marketers understands the platform's unique dynamics and trends, allowing us to craft engaging and viral content that captures attention.",
                img: tm1
            },
            {
                heading: "",
                para: "From developing creative concepts to producing high-quality videos and managing influencer partnerships, we ensure your brand stands out in the crowded TikTok landscape. With our data-driven approach and constant monitoring, we optimize your campaigns for maximum reach, engagement, and conversions. Experience the potential of TikTok marketing and connect with the rapidly growing TikTok community. Contact us today to discuss your marketing goals and let us help you achieve TikTok success.",
                img: tm2
            }
        ]
    },
    {
        key: "Snapchat-Marketing",
        name: "Snapchat Marketing",
        slides: [
            {
                heading: "",
                para: "Ignite your brand's presence with our specialized Snapchat marketing services. As a leading agency, we excel in creating dynamic Snapchat marketing strategies that resonate with your target audience. Our skilled team of Snapchat marketers understands the platform's unique features and user behavior, enabling us to craft engaging and captivating content that captures attention.",
                img: sm1
            },
            {
                heading: "",
                para: "From creating visually stunning Snap ads to designing interactive filters and leveraging influencer collaborations, we ensure your brand shines in the Snapchat ecosystem. With our data-driven approach and continuous optimization, we maximize your campaigns for optimal reach, engagement, and conversions. Experience the power of Snapchat marketing and tap into the vast potential of its user base. Contact us today to discuss your marketing objectives and let us help you achieve Snapchat success.",
                img: sm2
            }
        ]
    },
]

export default function ServiceSingle() {

    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);
    const slider1Ref = useRef(null);
    const slider2Ref = useRef(null);

    let [obj, setObj] = useState("")

    useEffect(() => {
        setNav1(slider1Ref.current);
        setNav2(slider2Ref.current);
    }, [obj]);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    useEffect(() => {
        let id = window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]
        let ob = content.find((a) => a.key === id)
        setObj(ob)
    }, [])


    return (
        obj &&
        <div className="serviceSingle">
            <div class="bg-img" >
                <Header />
                <div className="section1">
                    <div class="container">
                        {/* <div class="row align-items-center">
                            <div class="col-md-9">
                                <h6>Services/ Website Development</h6>
                                <h2>Website Development<strong>.</strong></h2>
                            </div>
                        </div> */}
                        <ReactTyped
                            // typedRef={typedRef()}
                            loop
                            typeSpeed={50}
                            backSpeed={20}
                            strings={[obj.name]}
                            smartBackspace
                            shuffle={false}
                            backDelay={1000}
                            fadeOut={false}
                            fadeOutDelay={100}
                            loopCount={0}
                            showCursor={false}
                        />
                    </div>
                </div>
            </div>

            <section class="servsec1">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-md-4">
                            <h4>{obj.name}</h4>
                        </div>
                        <div class="col-md-8">
                            <p>Mangopress Website Development Company specializes in web design and development for businesses, companies, and organizations. Our experience in the different branches of the digital medium, including brand development, web design, web and mobile web page and application programming, has allowed us to develop solutions tailored to organizations that seek to access, develop, and position themselves and strengthen their digital ecosystem.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section class="servsec2">
                <div class="container-fluid">
                    <div class="row align-items-center">
                        <div class="col-md-4">
                            <Slider
                                arrows={false}
                                dots={false}
                                asNavFor={nav2} 
                                ref={slider1Ref}
                                pauseOnHover={false}
                            >
                                {obj.slides.map((a, i) => (
                                    <div key={i}>
                                        {a.heading &&
                                            <h4>{a.heading}</h4>
                                        }
                                        {a.para &&
                                            <p>{a.para}</p>
                                        }
                                        <NavLink to="/contact-us" tabindex="-1">Let’s Connect</NavLink>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                        <div class="col-md-8 pad-r-zero">
                            <Slider
                                arrows={false}
                                dots={false}
                                asNavFor={nav1}
                                ref={slider2Ref}
                                slidesToShow={1}
                                swipeToSlide={true}
                                focusOnSelect={true}
                                autoplay
                                autoplaySpeed={3000}
                                pauseOnHover={false}
                            >

                                {obj.slides.map((a, i) => (
                                    <div key={i}>
                                        <img src={a.img} alt="" />
                                    </div>
                                ))}

                            </Slider>
                        </div>
                    </div>
                </div>
            </section >

            <section class="servsec7">
                <div class="container-fluid">
                    <div class="row servpb align-items-end">
                        <div class="col-md-6">
                            <h4>How much does a site cost?</h4>
                            <p>The cost depends a lot on two factors: the project, difficulty/size, and location/status of the business or company.</p>
                        </div>
                        <div class="col-md-6">
                            <p>Our team of professional web designers, developers and digital marketers bring in their talents. Insitech is all about delivering high quality web-based solutions at reasonable costs.</p>
                        </div>
                    </div>
                    {/* <div class="row">
                        <div class="col-md-4">
                            <div class="servvbox">
                                <h5>How long will it take to create my site?</h5>
                                <p>A quick reply, as always, is that it depends. Let's take as an example a standard site for a Software as a Service web startup. Generally, you have specific pages like the home page, the product page, the information page, careers, contacts, and service pages.
                                    <br />Our web design company will start with two weeks of discovery and research understanding your goals and requirements, examining the competition, and compiling design references. Our designers work on wireframes to establish core layouts and web design concepts to determine the look and feel of a future site. That's another four to six weeks.
                                    <br />As soon as the term is established, we work on the graphics, animations, photographs, videos and responsive designs for the seach page. For a hypothetical site, the production process for this site design can take about eight weeks. Frequent web development can run parallel with web design production and take six to ten weeks, depending on technical requirements.</p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="servvbox servvbox2">
                                <h5>What is the web design process?</h5>
                                <p>We at Insitech website design agency in UAE work as a team with the user's digital marketing department and are very transparent in everything we do. Now there are certain common steps in the web design process:
                                    <br />Before launching your site, we make sure it is well tested and without any glitches. It doesn't matter how nice your place is if it doesn't function properly or has poor performance. Usability testing is another surefire way to optimize your site before launching it to the public.</p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="servvbox servvbox3">
                                <h5>What is the web design process?</h5>
                                <p>Choose Insitech- your web design company in UAE. We are sure to be a good fit for your project. Before starting your project, we will spend hours studying online since web design is the most crowded design niche. With our impeccable website design services at a lower cost, we assure to identify a list of potential leads for your business. Trust us to witness the difference. With Insitech.ae web design agency in UAE, you can transform your site into an invaluable asset for your business and an effective means of communicating the essence and vision of your brand.
                                    <br />Most people visit a brand's site to learn about the company or find out about the products and services it offers. Today's users have high hopes for a company site. It should be simple to use, highly informative, visually appealing, attention-grabbing and memorable. People expect their site to work seamlessly on mobile devices, tablets, and desktop computers in all major browsers. In the era of direct-to-consumer Internet startups, a company's site has become the primary and only sales channel and the primary point of contact for brand expression. creative web design services now has considerably more ergonomics to do than graphic and text design.
                                    A decent site makes a lasting, positive first impression of your business. It is also a tool to earn big that gives unsurpassed user satisfaction by creating loyal service customers. Hence, you need to work with the best web design agency to develop your brand or business.</p>
                            </div>
                        </div>
                    </div> */}
                </div>
            </section>

            <ContactUs name={true} page={obj.name} />
        </div >
    )
}