import { useEffect } from "react";
import Header from "../../common/header";
import emailjs from 'emailjs-com';
import { useState } from "react";

export default function ContactUs(props) {

    let [name, setName] = useState("")
    let [email, setEmail] = useState("")
    let [message, setMessage] = useState("")

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    function sendEmail(e) {
        e.preventDefault()
        let obj = {
            name,
            email,
            message,
            page: !props.name ? "Contact" : props.page
        }
        emailjs.sendForm('service_j2ec0x4', 'template_cteyz6j', e.target, 'dUI_pMH3pFPS-3nzI')
            .then((result) => {
                console.log(result.text);
                setName("")
                setEmail("")
                setMessage("")
                document.getElementById("toastDiv").style.visibility = "visible"
                setTimeout(() => {
                    document.getElementById("toastDiv").style.visibility = "hidden"
                }, 2000)
            }, (error) => {
                console.log(error.text);
            });
    }

    return (
        <>
            {!props.name &&
                <Header bool={true} />
            }
            <section class="section6">

                <div style={{ zIndex: 2, position: "relative" }} class="row justify-content-end text-center alin-items-center">
                    <div class="col-lg-5 col-md-12">
                        <div class="head">
                            <h1>Contact us</h1>
                            <h2 className="text-white text-bold">WE WILL CALL YOU</h2>
                        </div>

                        <div class="form-div">
                            <form onSubmit={(e) => sendEmail(e)}>
                                <div class="input-div">
                                    <input required onChange={(e) => setName(e.target.value)} value={name} type="text" name="name" id="" placeholder="NAME" />
                                </div>
                                <div class="input-div">
                                    <input required onChange={(e) => setEmail(e.target.value)} value={email} type="text" name="email" id="" placeholder="MAIL" />
                                </div>
                                <div class="input-div">
                                    <textarea onChange={(e) => setMessage(e.target.value)} value={message} name="message" id="" rows="5" placeholder="MESSAGE"></textarea>
                                </div>

                                <div class="btn-div">
                                    <button type="submit">SEND MESSAGE</button>
                                </div>

                                <div className="toastDiv" id="toastDiv">
                                    <div class="alert alert-success" role="alert">
                                        Your Message has been received..
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}