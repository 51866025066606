import { NavLink } from "react-router-dom";
import Header from "../../common/header";
import ContactUs from "../contactUs/contactUs";
import ReactTyped from "react-typed";
import { useEffect } from "react";

export default function Services() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="services">
            <div class="b-img">
                <Header />
                <div class="section1">
                    {/* <div class="container">
                        <div class="row align-items-center">
                            <div class="col-md-9">
                                <h2>Services<strong>.</strong></h2>
                            </div>
                        </div>
                    </div> */}

                    <ReactTyped
                        // typedRef={typedRef()}
                        loop
                        typeSpeed={50}
                        backSpeed={20}
                        strings={["Services."]}
                        smartBackspace
                        shuffle={false}
                        backDelay={4000}
                        fadeOut={false}
                        fadeOutDelay={100}
                        loopCount={0}
                        showCursor={false}
                    />
                </div>
            </div>

            <section class="mnservsec">
                <div class="container-fluid">
                    <h4>We are a results-oriented company<br />that provides<br /> professional services.</h4>
                    <div class="row">
                        <div class="col-md-6">
                            <p>
                                Mangopress strives to provide top-notch digital solutions at a competitive price point, positioning ourselves as a reputable marketing agency. If you are in search of captivating marketing services that capture attention, we are dedicated to meeting your specific requirements and delivering exceptional results.
                            </p>
                        </div>
                        <div class="col-md-6">
                            <p>
                                Mangopress is a renowned Marketing Agency, known for its top-rated services. We are committed to delivering premium digital solutions that are both high in quality and affordable in price. Whether you are seeking an attention-grabbing web design or any other digital solution, we have you covered.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section class="mnservsec1">
                <div class="container-fluid">
                    <div class="row align-items-center reverse">
                        <div class="col-md-6">
                            <div class="text-right">
                                <img src="assets/images/SSM.png" alt="" />
                            </div>
                            <div>
                            </div>
                        </div>
                        <div class="col-md-6 icon-div">
                            <img src="assets/images/service6.png" alt="" />
                            <h4>Social Media Marketing</h4>
                            <p>
                                Mangopress, a prominent Social Media Marketing agency, excels in delivering effective strategies and solutions for businesses, companies, and organizations. With our expertise in the realm of digital media, we offer specialized services in brand development, social media management, content strategy, and campaign optimization. Trust us to elevate your social media presence and drive impactful results.
                            </p>
                            <NavLink to="/services/Social-Media-Marketing">Learn More</NavLink>
                        </div>
                    </div>
                </div>
            </section>

            <section class="mnservsec1">
                <div class="container-fluid">
                    <div class="row align-items-center">
                        <div class="col-md-6 icon-div">
                            <img src="assets/images/service1.png" alt="" />
                            <h4>Website Design and Development</h4>
                            <p>
                                As a leading website development company, Mangopress specializes in providing web design and development services for businesses, companies, and organizations. Our extensive experience across various digital domains, including brand development, web design, and programming for web and mobile applications, enables us to offer tailored solutions to organizations aiming to establish, grow, position, and enhance their digital presence.
                            </p>
                            <NavLink to="/services/Website-Design-and-Development">Learn More</NavLink>
                        </div>
                        <div class="col-md-6">
                            <div class="text-right">
                                <img src="assets/images/ecommerce.png" alt="" />
                            </div>
                            <div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="mnservsec1">
                <div class="container-fluid">
                    <div class="row align-items-center reverse">
                        <div class="col-md-6">
                            <div class="text-right">
                                <img src="assets/images/creativeDesigning.png" alt="" />
                            </div>
                            <div>
                            </div>
                        </div>
                        <div class="col-md-6 icon-div">
                            <img src="assets/images/service5.png" alt="" />
                            <h4>Creative Designing</h4>
                            <p>
                                Mangopress, a leading Creative Designing agency, specializes in transforming ideas into visually stunning and engaging experiences. Our team of talented designers brings your brand to life through innovative graphic design, logo creation, branding, and visual identity development. With a keen eye for aesthetics and a passion for creativity, we deliver impactful designs that captivate your audience and elevate your brand presence.
                            </p>
                            <NavLink to="/services/Creative-Designing">Learn More</NavLink>
                        </div>
                    </div>
                </div>
            </section>

            <section class="mnservsec1">
                <div class="container-fluid">
                    <div class="row align-items-center">
                        <div class="col-md-6 icon-div">
                            <img src="assets/images/service3.png" alt="" />
                            <h4>Google Ads</h4>
                            <p>
                                Mangopress, a renowned Google Ads agency, is dedicated to maximizing your online advertising success. Our expertise lies in creating targeted campaigns, optimizing keywords, crafting compelling ad copy, and monitoring performance to ensure optimal ROI. With our proficiency in the Google Ads platform, we drive quality traffic and help businesses achieve their advertising goals effectively.
                            </p>
                            <NavLink to="/services/Google-Ads">Learn More</NavLink>
                        </div>
                        <div class="col-md-6">
                            <div class="text-right">
                                <img src="assets/images/googleAds.webp" alt="" />
                            </div>
                            <div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="mnservsec1">
                <div class="container-fluid">
                    <div class="row align-items-center reverse">
                        <div class="col-md-6">
                            <div class="text-right">
                                <img src="assets/images/seo.png" alt="" />
                            </div>
                            <div>
                            </div>
                        </div>
                        <div class="col-md-6 icon-div">
                            <img src="assets/images/service2.png" alt="" />
                            <h4>Search Engine Optimization</h4>
                            <p>
                                Mangopress, a leading Search Engine Optimization (SEO) agency, specializes in driving organic visibility and ranking for businesses, companies, and organizations. Leveraging our deep understanding of the digital landscape, we offer tailored solutions in website optimization, keyword research, content strategy, and link building. Partner with us to enhance your online presence and achieve sustainable growth in search engine rankings.
                            </p>
                            <NavLink to="/services/Search-Engine-Optimization">Learn More</NavLink>
                        </div>
                    </div>
                </div>
            </section>

            <section class="mnservsec1">
                <div class="container-fluid">
                    <div class="row align-items-center">
                        <div class="col-md-6 icon-div">
                            <img src="assets/images/service4.png" alt="" />
                            <h4>Content Writing</h4>
                            <p>
                                Mangopress, a premier Content Writing agency, excels in crafting compelling and impactful content for businesses, companies, and organizations. Our skilled team of writers creates engaging website copy, informative blog articles, persuasive marketing materials, and captivating social media content. With a focus on delivering quality and value, we ensure that your brand's message resonates with your target audience, driving meaningful connections and results.
                            </p>
                            <NavLink to="/services/Content-Writing">Learn More</NavLink>
                        </div>
                        <div class="col-md-6">
                            <div class="text-right">
                                <img src="assets/images/contentWriting.png" alt="" />
                            </div>
                            <div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="mnservsec1">
                <div class="container-fluid">
                    <div class="row align-items-center reverse">
                        <div class="col-md-6">
                            <div class="text-right">
                                <img src="assets/images/emailMarketing.png" alt="" />
                            </div>
                            <div>
                            </div>
                        </div>
                        <div class="col-md-6 icon-div">
                            <img src="assets/images/service4.png" alt="" />
                            <h4>Email Marketing</h4>
                            <p>
                                Mangopress, a distinguished Email Marketing agency, specializes in creating effective email campaigns that engage and convert. Our team of experts leverages strategic planning, compelling content, and eye-catching designs to deliver targeted messages directly to your audience's inbox. With our proven expertise in email automation, segmentation, and analytics, we help businesses build strong customer relationships, drive conversions, and achieve measurable success through impactful email marketing strategies.
                            </p>
                            <NavLink to="/services/Email-Marketing">Learn More</NavLink>
                        </div>
                    </div>
                </div>
            </section>

            <section class="mnservsec1">
                <div class="container-fluid">
                    <div class="row align-items-center">
                        <div class="col-md-6 icon-div">
                            <img src="assets/images/service8.png" alt="" />
                            <h4>Tiktok Marketing</h4>
                            <p>
                                Mangopress, a prominent TikTok Marketing agency, harnesses the power of this popular platform to elevate your brand's reach and engagement. Our team of specialists understands the dynamics of TikTok's audience and creates captivating video content that resonates with your target market. With our strategic approach, we help businesses leverage the platform's viral potential, build brand awareness, and drive meaningful connections with a younger and highly engaged audience. Partner with us to unlock the full potential of TikTok marketing and achieve impactful results.
                            </p>
                            <NavLink to="/services/Tiktok-Marketing">Learn More</NavLink>
                        </div>
                        <div class="col-md-6">
                            <div class="text-right">
                                <img src="assets/images/tiktok.jpg" alt="" />
                            </div>
                            <div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="mnservsec1">
                <div class="container-fluid">
                    <div class="row align-items-center reverse">
                        <div class="col-md-6">
                            <div class="text-right">
                                <img src="assets/images/snapchat.webp" alt="" />
                            </div>
                            <div>
                            </div>
                        </div>
                        <div class="col-md-6 icon-div">
                            <img src="assets/images/service9.png" alt="" />
                            <h4>Snapchat Marketing</h4>
                            <p>Mangopress, a leading Snapchat Marketing agency, empowers businesses to connect with their target audience through innovative and captivating campaigns. Our team of experts leverages the unique features of Snapchat to create engaging content that resonates with your ideal customers. From interactive filters to sponsored stories, we help businesses build brand awareness, drive user engagement, and generate impactful results on this popular platform. Partner with us to unlock the full potential of Snapchat marketing and stay ahead in the digital landscape.</p>
                            <NavLink to="/services/Snapchat-Marketing">Learn More</NavLink>
                        </div>
                    </div>
                </div>
            </section>

            <ContactUs name={true} page="Service" />
        </div>
    )
}