import ReactTyped from "react-typed";
import Header from "../../common/header";
import ContactUs from "../contactUs/contactUs";
import { NavLink } from "react-router-dom";
import { useEffect } from "react";

export default function AboutUs() {
    
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="about-us">
            <div class="b-img">
                <Header />
                <div class="section1">
                    <div class="container">
                        {/* <div class="row">
                            <div class="col-md-9">
                                <h6>About Us</h6>
                                <h2>The innovative website agency in <strong></strong></h2>
                            </div>
                        </div> */}

                        <ReactTyped
                            // typedRef={typedRef()}
                            loop
                            typeSpeed={50}
                            backSpeed={20}
                            strings={["The innovative Marketing agency in the world."]}
                            smartBackspace
                            shuffle={false}
                            backDelay={1000}
                            fadeOut={false}
                            fadeOutDelay={100}
                            loopCount={0}
                            showCursor={false}
                        />
                    </div>
                </div>
            </div>

            <section class="abtsec1">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-md-5">
                            <h4><strong>Dr</strong>eam. <br />Cre<span>ate</span><strong>.</strong> <br />Ins<strong>pi</strong>re.</h4>
                        </div>
                        <div class="col-md-7">
                            <div class="abtsecpad1">
                                <p>
                                    Mangopress, a marketing agency, focuses on providing straightforward solutions for small businesses and entrepreneurs aiming to establish a digital presence promptly, efficiently, and pragmatically. As a combined marketing agency and web development firm, we are committed to equipping our clients with essential tools to create a robust online sales channel.
                                </p>
                                <NavLink to="/contact-us">Let’s Connect</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section class="abtsec2">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-md-6">
                            <div>
                                <img src="assets/images/abtsectwoimg.jpg" alt="" />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <h4>Do you have a <span>project in mind?</span></h4>
                            <p>Mangopress goes beyond being just a marketing agency; it serves as a specialized digital consultancy with a strong focus on SEO. Our wide range of services caters to companies aiming to establish a strong brand presence. We are dedicated to supporting your growth, so reach out to us today and let us assist you!</p>
                           </div>
                    </div>
                </div>
            </section>

            <section class="servsec6 uxexservsec" >
                <div class="container-fluid">
                    <div class="row align-items-center">
                        <div class="col-md-6">
                            <div class="servsec6con">
                                <h4>Why choose Mangopress</h4>
                                <p>Mangopress stands out as a leading digital marketing agency, renowned for its exceptional expertise in SEO web positioning. Our agency provides a comprehensive range of services, including SEO, paid advertising strategies, professional design, and web content writing, setting us apart from others in the industry.</p>
                                <p>What sets us apart is our unique offering of lead-generation services, a rarity among digital marketing agencies. Our diverse services encompass corporate identity, mailing campaigns, search engine optimization, mobile application development, account management, and the creation and monitoring of promotions.</p>
                                <p>Our agency is structured into three core stages:</p>
                                <ul class="srvlst">
                                    <li class="first">Digital Marketing.</li>
                                    <li>Creative Designing.</li>
                                    <li>Social Media (content strategy and management).</li>
                                    <li>Design & Development (web design and mobile web).</li>
                                    <li class="last">Mobile web (responsive design, publication on Google Play and App Store).</li>
                                </ul>
                            </div>
                            <NavLink to="/contact-us">Let’s Connect</NavLink>
                        </div>
                        <div class="col-md-6">
                            <div class="servsidesix">
                                <h5>No project is small enough.</h5>
                                <p>We all have a dream, and many times we want to start our venture; however, we may not have the financial resources or technological experience. That's why we want you to tell us about your dream, and together we will work to move it forward.</p>
                                <h5>Let’s connect the online world with the offline world! </h5>
                                <p>At Mangopress, we go beyond the conventional approach of simply showcasing services like other digital marketing agencies. Instead, we focus on creating a robust portfolio that exemplifies our expertise, highlights successful projects, and demonstrates the tangible value we bring to our clients.</p>
                                <img src="assets/images/bg-services-single.jpg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <ContactUs name={true} page="About" />
        </div>
    )
}