import ReactTyped from "react-typed";
import Header from "../../common/header";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import { NavLink } from "react-router-dom";
// import test from "./website-banner2.png"
import { tab } from "@testing-library/user-event/dist/tab";
import Data from "../Data"



export default function Portfolio() {
    var [Tab, setTab] = useState("")



    var cat = (Data.filter((item) => {
        if (Tab === item.type) {
            return (item)
        }
    }))



    return (
        <div className="portfolio">
            <div class="b-img">
                <Header />
                <div class="section1">
                    <div class="container">
                        {/* <div class="row align-items-center">
                            <div class="col-md-9">
                                <h6>Portfolio</h6>
                                <h2>
                                    When creativity meets productivity<strong>.</strong>
                                </h2>
                            </div>
                        </div> */}
                        <ReactTyped
                            // typedRef={typedRef()}
                            loop
                            typeSpeed={50}
                            backSpeed={20}
                            strings={["When creativity meets productivity."]}
                            smartBackspace
                            shuffle={false}
                            backDelay={1000}
                            fadeOut={false}
                            fadeOutDelay={100}
                            loopCount={0}
                            showCursor={false}
                        />
                    </div>
                </div>
            </div>

            <div className="section2">
                <div className="container">
                    <nav>
                        <div class="nav nav-tabs" id="nav-tab" role="tablist">

                            <a class={`nav-item nav-link `} onClick={() => { setTab("logo"); console.log(Tab) }}  >Logo Design</a>
                            <a class={`nav-item nav-link `} onClick={() => { setTab("social") }}   >Social Media Design</a>
                            <a class={`nav-item nav-link `} onClick={() => { setTab("web") }}  >Web Design</a>

                            {/* <a class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">Social Media Marketing</a>
                            <a class="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-contact" role="tab" aria-controls="nav-contact" aria-selected="false">creative designing</a> */}
                        </div>
                    </nav>


                    <div class="tab-content" >
                        <div class="tab-pane fade show active">
                            <div class="row">

                                {cat.map((e) => {
                                    return (
                                        < div class="col-md-3" >
                                            <div class="shwimg">
                                                <a href={e.img} target="_blank" ><img src={e.img} alt="" /></a>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    {/* {Tab === "logo" ?
                        <div class="tab-content" >
                            <div class="tab-pane fade show active">
                                <div class="row">
                                    <div class="col-md-3">
                                        <div class="shwimg">
                                            <a href="" ><img src="https://insitech.ae/wp-content/uploads/2022/08/NYC.jpg" alt="" /></a>
                                        </div>
                                    </div>

                                    <div class="col-md-3">
                                        <div class="shwimg">
                                            <a href="" ><img src="https://insitech.ae/wp-content/uploads/2022/08/mooncycle.jpg" alt="" /></a>
                                        </div>
                                    </div>

                                    <div class="col-md-3">
                                        <div class="shwimg">
                                            <a href="" ><img src="https://insitech.ae/wp-content/uploads/2022/08/Kookos.jpg" alt="" /></a>
                                        </div>
                                    </div>

                                    <div class="col-md-3">
                                        <div class="shwimg">
                                            <a href="" ><img src="https://insitech.ae/wp-content/uploads/2022/08/DesiRekkins.jpg" alt="" /></a>
                                        </div>
                                    </div>

                                    <div class="col-md-3">
                                        <div class="shwimg">
                                            <a href="" ><img src="https://insitech.ae/wp-content/uploads/2022/08/CharlieJak.jpg" alt="" /></a>
                                        </div>
                                    </div>

                                    <div class="col-md-3">
                                        <div class="shwimg">
                                            <a href="" ><img src="https://insitech.ae/wp-content/uploads/2022/08/AketBoy.jpg" alt="" /></a>
                                        </div>
                                    </div>

                                    <div class="col-md-3">
                                        <div class="shwimg">
                                            <a href="" ><img src="https://insitech.ae/wp-content/uploads/2022/08/AfroVertu.jpg" alt="" /></a>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="shwimg">
                                            <a href="" ><img src="https://insitech.ae/wp-content/uploads/2022/08/2Bs.jpg" alt="" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : <></>}

                    {Tab === "social" ?
                        <div class=" tab-content" style={{ marginTop: "25px" }} >
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="shwimg">
                                        <a href=""
                                        ><img src="https://insitech.ae/wp-content/uploads/2022/08/SocialMedia01.jpg" alt="" /></a>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="shwimg">
                                        <a href=""
                                        ><img src="https://insitech.ae/wp-content/uploads/2022/08/SocialMedia02.jpg" alt="" /></a>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="shwimg">
                                        <a href=""
                                        ><img src="https://insitech.ae/wp-content/uploads/2022/08/SocialMedia03.jpg" alt="" /></a>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="shwimg">
                                        <a href=""
                                        ><img src="https://insitech.ae/wp-content/uploads/2022/08/SocialMedia04.jpg" alt="" /></a>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="shwimg">
                                        <a href=""
                                        ><img src="https://insitech.ae/wp-content/uploads/2022/08/SocialMedia05.jpg" alt="" /></a>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="shwimg">
                                        <a href=""
                                        ><img src="https://insitech.ae/wp-content/uploads/2022/08/SocialMedia06.jpg" alt="" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : <></>} */}


                    {/* <div class={`tab-pane fade`} >
                            <div class="row">


                                <div class="col-md-4">
                                    <div class="shwimg">
                                        <a><img src="https://designsquads.com/wp-content/uploads/2023/04/Avian-Bloodline_Mockup_02_7_11zon-scaled.webp" alt="" width={100} /></a>
                                    </div>
                                </div>

                            </div>
                        </div> */}

                    {/* <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="shwimg">
                                        <a href=""
                                             ><img src="https://insitech.ae/wp-content/uploads/2022/08/SocialMedia01.jpg" alt="" /></a>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="shwimg">
                                        <a href=""
                                             ><img src="https://insitech.ae/wp-content/uploads/2022/08/SocialMedia02.jpg" alt="" /></a>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="shwimg">
                                        <a href=""
                                             ><img src="https://insitech.ae/wp-content/uploads/2022/08/SocialMedia03.jpg" alt="" /></a>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="shwimg">
                                        <a href=""
                                             ><img src="https://insitech.ae/wp-content/uploads/2022/08/SocialMedia04.jpg" alt="" /></a>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="shwimg">
                                        <a href=""
                                             ><img src="https://insitech.ae/wp-content/uploads/2022/08/SocialMedia05.jpg" alt="" /></a>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="shwimg">
                                        <a href=""
                                             ><img src="https://insitech.ae/wp-content/uploads/2022/08/SocialMedia06.jpg" alt="" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="shwimg">
                                        <a href="" ><img src="https://insitech.ae/wp-content/uploads/2022/08/NYC.jpg" alt="" /></a>
                                    </div>
                                </div>

                                <div class="col-md-3">
                                    <div class="shwimg">
                                        <a href="" ><img src="https://insitech.ae/wp-content/uploads/2022/08/mooncycle.jpg" alt="" /></a>
                                    </div>
                                </div>

                                <div class="col-md-3">
                                    <div class="shwimg">
                                        <a href="" ><img src="https://insitech.ae/wp-content/uploads/2022/08/Kookos.jpg" alt="" /></a>
                                    </div>
                                </div>

                                <div class="col-md-3">
                                    <div class="shwimg">
                                        <a href="" ><img src="https://insitech.ae/wp-content/uploads/2022/08/DesiRekkins.jpg" alt="" /></a>
                                    </div>
                                </div>

                                <div class="col-md-3">
                                    <div class="shwimg">
                                        <a href="" ><img src="https://insitech.ae/wp-content/uploads/2022/08/CharlieJak.jpg" alt="" /></a>
                                    </div>
                                </div>

                                <div class="col-md-3">
                                    <div class="shwimg">
                                        <a href="" ><img src="https://insitech.ae/wp-content/uploads/2022/08/AketBoy.jpg" alt="" /></a>
                                    </div>
                                </div>

                                <div class="col-md-3">
                                    <div class="shwimg">
                                        <a href="" ><img src="https://insitech.ae/wp-content/uploads/2022/08/AfroVertu.jpg" alt="" /></a>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="shwimg">
                                        <a href="" ><img src="https://insitech.ae/wp-content/uploads/2022/08/2Bs.jpg" alt="" /></a>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                </div>
            </div>
        </div >

    )
}

