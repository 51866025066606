const Data = [
    {
        "id": 1,
        "img": "https://insitech.ae/wp-content/uploads/2022/08/NYC.jpg",
        "type": "logo",
    },
    {
        "id": 2,
        "img": "https://insitech.ae/wp-content/uploads/2022/08/mooncycle.jpg",
        "type": "logo",
    },
    {
        "id": 3,
        "img": "https://insitech.ae/wp-content/uploads/2022/08/Kookos.jpg",
        "type": "logo",
    },
    {
        "id": 4,
        "img": "https://insitech.ae/wp-content/uploads/2022/08/DesiRekkins.jpg",
        "type": "logo",
    },
    {
        "id": 5,
        "img": "https://insitech.ae/wp-content/uploads/2022/08/CharlieJak.jpg",
        "type": "logo",
    },
    {
        "id": 6,
        "img": "https://insitech.ae/wp-content/uploads/2022/08/AketBoy.jpg",
        "type": "logo",
    },
    {
        "id": 7,
        "img": "https://insitech.ae/wp-content/uploads/2022/08/2Bs.jpg",
        "type": "logo",
    },
    {
        "id": 8,
        "img": "https://insitech.ae/wp-content/uploads/2022/08/SocialMedia01.jpg",
        "type": "social",
    },
    {
        "id": 9,
        "img": "https://insitech.ae/wp-content/uploads/2022/08/SocialMedia02.jpg",
        "type": "social",
    },
    {
        "id": 10,
        "img": "https://insitech.ae/wp-content/uploads/2022/08/SocialMedia03.jpg",
        "type": "social",
    },
    {
        "id": 11,
        "img": "https://insitech.ae/wp-content/uploads/2022/08/SocialMedia04.jpg",
        "type": "social",
    },
    {
        "id": 12,
        "img": "https://insitech.ae/wp-content/uploads/2022/08/SocialMedia05.jpg",
        "type": "social",
    },
    {
        "id": 13,
        "img": "https://insitech.ae/wp-content/uploads/2022/08/SocialMedia06.jpg",
        "type": "social",
    },
    {
        "id": 14,
        "img": "https://insitech.ae/wp-content/uploads/2022/08/CanadianJoist.png",
        "type": "web",
    },
    {
        "id": 15,
        "img": "https://insitech.ae/wp-content/uploads/2022/08/Canizaleze.png",
        "type": "web",
    },
    {
        "id": 16,
        "img": "https://insitech.ae/wp-content/uploads/2022/08/greenligghtsolar.png",
        "type": "web",
    },
    {
        "id": 17,
        "img": "https://insitech.ae/wp-content/uploads/2022/08/hopefordogs.png",
        "type": "web",
    },
    {
        "id": 18,
        "img": "https://insitech.ae/wp-content/uploads/2022/08/theateracademy.png",
        "type": "web",
    },
    {
        "id": 19,
        "img": "https://insitech.ae/wp-content/uploads/2022/08/zdapt.png",
        "type": "web",
    },

]
export default Data